import { AttributeTitle, AttributeType, SuperAdminAttributeDto } from "api-shared";
import { FormEventHandler } from "react";
import { Control, Controller } from "react-hook-form";
import Form from "../../../components/Form";
import Select from "../../../components/input/select/Select";
import { FieldRelationIdeasFormData } from "./IdeaFieldRelationDialog";

interface IdeaFieldRelationFormProps {
    onSubmit: FormEventHandler<HTMLFormElement>;
    control: Control<FieldRelationIdeasFormData>;
    attributes: SuperAdminAttributeDto[];
    attributeIdsInUse: number[];
    clientId: number;
}

const IdeaFieldRelationForm = ({ control, attributes, attributeIdsInUse, onSubmit, clientId }: IdeaFieldRelationFormProps) => {
    const assignableAttributes = attributes
        .filter(({ clientId: cId }) => cId === null || cId === clientId)
        .filter(
            ({ title, type }) =>
                type !== AttributeType.Text &&
                title !== AttributeTitle.OriginatorClient &&
                title !== AttributeTitle.DiscardReason &&
                title !== AttributeTitle.DiscardStatement,
        );

    const attributeOptions = assignableAttributes.map(({ id, title }) => ({ value: id, label: title }));

    return (
        <Form onSubmit={onSubmit}>
            <Controller
                name="attributeId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                    // extract ref here because forwarding it to Select causes a warning
                    const { value, onChange, ref, ...inputProps } = field;
                    const availableOptions = attributeOptions.filter(
                        (o) => o.value === value || !attributeIdsInUse.some((id) => o.value === id),
                    );
                    const selectedOption = availableOptions.find((o) => o.value === value);

                    return (
                        <Select
                            value={selectedOption}
                            options={availableOptions}
                            onChange={(o) => onChange(o?.value ?? null)}
                            menuPortalTarget={document.body}
                            label="Attribute"
                            {...inputProps}
                        />
                    );
                }}
            />
        </Form>
    );
};

export default IdeaFieldRelationForm;
