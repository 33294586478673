import { Plugin, Template, TemplateConnector, TemplatePlaceholder } from "@devexpress/dx-react-core";
import {
    ChangeSet,
    Column,
    DataTypeProvider,
    DataTypeProviderProps,
    EditingState,
    Filter,
    FilteringState,
    IntegratedFiltering,
    IntegratedPaging,
    IntegratedSorting,
    PagingState,
    Sorting,
    SortingState,
} from "@devexpress/dx-react-grid";
import { Grid as DxGrid, PagingPanel, Table, TableEditColumn, TableFilterRow, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";
import { SuperAdminAttributeDto, SuperAdminTranslationDto, TranslationType } from "api-shared";
import { TFunction } from "i18next";
import React, { ReactElement, useMemo, useState } from "react";
import DataGridCommandCell, { IDataGridCommandCellProps } from "../../../components/datagrid/DataGridCommandCell";
import DataGridNoDataComponent from "../../../components/datagrid/DataGridNoDataComponent";
import CustomPagingPanelContainer from "../../../components/datagrid/DataGridPaginationPane";
import { translationKeys } from "../../../translations/main-translations";
import CustomFieldDialog from "./CustomFieldDialog";

const pageSizes = [10, 25, 50];

const BooleanFormatter = ({ value }: DataTypeProvider.ValueFormatterProps) => {
    return <>{value ? 1 : 0}</>;
};

const BooleanTypeProvider = (props: DataTypeProviderProps) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;

export interface ICustomField extends SuperAdminAttributeDto {
    translationEn: string;
    translationDe: string;
    hintEn: string;
    hintDe: string;
    translateCustomValues: boolean;
}

interface ICustomFieldsTableProps {
    clientId: number | null;
    items: SuperAdminAttributeDto[];
    translate: TFunction;
    translations: SuperAdminTranslationDto[];
    createCustomField: (customField: ICustomField) => void;
    updateCustomField: (customField: ICustomField) => void;
    deleteCustomField: (customFieldId: number) => void;
}

const CustomFieldsTable = ({
    clientId,
    items = [],
    translations = [],
    translate,
    createCustomField,
    updateCustomField,
    deleteCustomField,
}: ICustomFieldsTableProps) => {
    const injectDisabledState = (child: ReactElement<IDataGridCommandCellProps>, row: SuperAdminAttributeDto) => {
        let isDisabled = false;
        if (child?.props.id === "delete") {
            isDisabled = Boolean(row.isFilled);
        }
        if (isDisabled) {
            return React.cloneElement(child, { disabled: true });
        }
        return child;
    };

    const getNameTranslation = (row: SuperAdminAttributeDto, columnName: string) => {
        const t = translations.find((t) => t.key === row.title && t.clientId === row.clientId);
        return t ? t[columnName as keyof typeof t] : "";
    };

    const getHintTranslation = (row: SuperAdminAttributeDto, columnName: string) => {
        const t = translations.find((t) => t.key === row.title + "Hint" && t.clientId === row.clientId);
        const name = columnName === "hintEn" ? "translationEn" : "translationDe";
        return t ? t[name] : "";
    };

    const defaultColumns: Column[] = [
        { name: "title", title: "Name (internal)" },
        { name: "translationEn", title: "Name (EN)", getCellValue: getNameTranslation },
        { name: "translationDe", title: "Name (DE)", getCellValue: getNameTranslation },
        { name: "hintEn", title: "Hint (EN)", getCellValue: getHintTranslation },
        { name: "hintDe", title: "Hint (DE)", getCellValue: getHintTranslation },
        { name: "type", title: "Type" },
        { name: "isMulti", title: "Multi" },
        { name: "isCreatable", title: "Creatable" },
        { name: "translate", title: "translate" },
        { name: "tableName", title: "Table name" },
    ];

    const [sorting, setSorting] = useState<Sorting[]>([{ columnName: defaultColumns[0].title ?? "", direction: "asc" }]);
    const [filters, setFilters] = useState<Filter[]>([]);

    const PopupEditing = React.memo(({ popupComponent: Popup, translate }: { popupComponent: any; translate: TFunction }) => (
        <Plugin name="PopupEditing">
            <Template name="popupEditing">
                <TemplateConnector>
                    {(
                        { rows, getRowId, addedRows, editingRowIds, createRowChange, rowChanges },
                        { changeRow, changeAddedRow, commitChangedRows, commitAddedRows, stopEditRows, cancelAddedRows, cancelChangedRows },
                    ) => {
                        const isNew = addedRows.length > 0;
                        let editedRow: Record<string, unknown>;
                        let rowId = 0;
                        if (isNew) {
                            rowId = 0;
                            editedRow = addedRows[rowId];
                        } else {
                            [rowId] = editingRowIds;
                            const targetRow = rows.filter((row: Record<string, unknown>) => getRowId(row) === rowId)[0];
                            if (targetRow) {
                                targetRow.translationEn = getNameTranslation(targetRow, "translationEn");
                                targetRow.translationDe = getNameTranslation(targetRow, "translationDe");
                                targetRow.hintEn = getHintTranslation(targetRow, "hintEn");
                                targetRow.hintDe = getHintTranslation(targetRow, "hintDe");
                                if (targetRow.options) {
                                    targetRow.translateCustomValues = targetRow.options.translate !== TranslationType.Map;
                                }
                            }
                            editedRow = { ...targetRow, ...rowChanges[rowId] };
                        }

                        const processValueChange = ({ target: { name, value } }: { target: { name: string; value: string | boolean } }) => {
                            const changeArgs = {
                                rowId,
                                change: createRowChange(editedRow, value, name),
                            };
                            if (isNew) {
                                changeAddedRow(changeArgs);
                            } else {
                                changeRow(changeArgs);
                            }
                        };

                        const rowIds = isNew ? [0] : editingRowIds;
                        const applyChanges = () => {
                            if (isNew) {
                                commitAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                commitChangedRows({ rowIds });
                            }
                        };

                        const cancelChanges = () => {
                            if (isNew) {
                                cancelAddedRows({ rowIds });
                            } else {
                                stopEditRows({ rowIds });
                                cancelChangedRows({ rowIds });
                            }
                        };

                        const open = editingRowIds.length > 0 || isNew;
                        const originalRow = items.find((item) => item.id === editedRow.id);

                        return (
                            <Popup
                                open={open}
                                row={editedRow}
                                originalRow={originalRow}
                                onChange={processValueChange}
                                onApplyChanges={applyChanges}
                                onCancelChanges={cancelChanges}
                                translate={translate}
                                isNew={isNew}
                                clientId={clientId}
                            />
                        );
                    }}
                </TemplateConnector>
            </Template>
            <Template name="root">
                <TemplatePlaceholder />
                <TemplatePlaceholder name="popupEditing" />
            </Template>
        </Plugin>
    ));

    const commitChanges = ({ added, deleted, changed }: ChangeSet) => {
        if (added) {
            createCustomField({ ...added[0], clientId });
        } else if (changed) {
            const row = items.find((item) => changed[item.id] != null);
            if (row) {
                updateCustomField({ ...row, ...changed[row.id] });
            }
        } else if (deleted) {
            deleted.forEach((id) => deleteCustomField(+id));
        }
    };

    const booleanColumns = ["isMulti", "isCreatable"];

    const rowPerPageText = useMemo(() => ({ rowsPerPage: translate(translationKeys.VDLANG_PAGING_ROWS_PER_PAGE) }), [translate]);

    const TableEditColumnCell = ({ children, ...props }: TableEditColumn.CellProps) => {
        const row = props.row as SuperAdminAttributeDto;
        return (
            <TableEditColumn.Cell {...props}>
                {React.Children.map(children as ReactElement<IDataGridCommandCellProps>[], (child) => injectDisabledState(child, row))}
            </TableEditColumn.Cell>
        );
    };

    const addCommand = clientId !== null ? { showAddCommand: true } : "";

    return (
        <DxGrid rows={items} columns={defaultColumns} getRowId={(r) => r.id}>
            {/* Order of plugins is important!
                https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/plugin-overview#plugin-order */}
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <FilteringState filters={filters} onFiltersChange={setFilters} />
            <PagingState defaultCurrentPage={0} defaultPageSize={25} />
            <EditingState onCommitChanges={commitChanges} />

            <IntegratedSorting />
            <IntegratedFiltering />
            <IntegratedPaging />

            <PopupEditing popupComponent={CustomFieldDialog} translate={translate} />

            <Table noDataCellComponent={DataGridNoDataComponent} />
            <BooleanTypeProvider for={booleanColumns} />

            <TableHeaderRow showSortingControls />
            <TableFilterRow />
            <TableEditColumn
                {...addCommand}
                showEditCommand
                showDeleteCommand
                commandComponent={DataGridCommandCell}
                cellComponent={TableEditColumnCell}
            />

            <PagingPanel containerComponent={CustomPagingPanelContainer} pageSizes={pageSizes} messages={rowPerPageText} />
        </DxGrid>
    );
};

export default CustomFieldsTable;
