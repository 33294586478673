import { Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material";
import { AttributeTable, AttributeType, TranslationType, validateCustomFieldName } from "api-shared";
import { TFunction } from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Form from "../../../components/Form";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import { useSuperadminTranslations } from "../../../domain/superadmin/translations";
import { translationKeys } from "../../../translations/main-translations";

const typeOptions: Option[] = [
    { value: AttributeType.Text, label: "Text" },
    { value: AttributeType.Combobox, label: "Combobox" },
    { value: AttributeType.Tree, label: "Tree" },
];
const tableNameOptions: Option[] = [
    { value: AttributeTable.Companies, label: AttributeTable.Companies },
    { value: AttributeTable.CostLevers, label: AttributeTable.CostLevers },
    { value: AttributeTable.CustomValues, label: AttributeTable.CustomValues },
    { value: AttributeTable.Departments, label: AttributeTable.Departments },
    { value: AttributeTable.Projects, label: AttributeTable.Projects },
    { value: AttributeTable.Suppliers, label: AttributeTable.Suppliers },
    { value: AttributeTable.Users, label: AttributeTable.Users },
];

const validateValue = (value: string | number | null) => value != null && (typeof value !== "string" || value.trim().length > 0);

const validateCustomField = (row: Record<string, unknown>): boolean => {
    return ["title", "translationEn", "translationDe", "type", "tableName"].every((field) => {
        if (field === "tableName" && (row.type === "text" || row.type === "tree")) {
            return true;
        }
        return validateValue(row[field] as string);
    });
};

const validateFieldName = (value: string) => validateValue(value) && validateCustomFieldName(value);

interface CreateCustomFieldDialogProps {
    open: boolean;
    row: Record<string, unknown>;
    originalRow?: Record<string, unknown>; // The currently saved field if it exists
    onChange: ({ target }: { target: { name: string; value: string | boolean | number | null } }) => void;
    onApplyChanges: () => void;
    onCancelChanges: () => void;
    translate: TFunction;
    isNew: boolean;
    clientId: number;
}

const CustomFieldDialog = ({
    open,
    row,
    originalRow,
    onChange,
    onApplyChanges,
    onCancelChanges,
    translate,
    isNew,
    clientId,
}: CreateCustomFieldDialogProps) => {
    const [isCustomFieldValid, setIsCustomFieldValid] = useState(false);

    useEffect(() => {
        setIsCustomFieldValid(validateCustomField(row));
    }, [row]);

    const translations = useSuperadminTranslations(clientId);
    const { t } = useTranslation();

    const customField = isNew ? row : originalRow;

    if (translations.data === undefined || customField === undefined) {
        return null;
    }

    const validateInternalName = (value: string, clientId: number) => {
        const clientTranslationKeys = translations.data.filter((t) => t.clientId === clientId).map((t) => t.key.toLowerCase());
        // Lower-case comparisons since MySQL's unique constraints are case insensitive by default
        return !clientTranslationKeys.includes(value.toLowerCase().trim()) || !isNew;
    };

    const onSelectInputChanged = (name: string, option: Option<string | boolean> | null) => {
        const value = option !== null ? option.value : "";

        if (name !== "type") {
            const target = { name, value };

            onChange({ target });

            // special handling for costlevers and departments
            if (name === "tableName") {
                if (value === AttributeTable.CostLevers || value === AttributeTable.Departments) {
                    onChange({ target: { name: "translate", value: TranslationType.MapToLangProperty } });
                } else {
                    onChange({ target: { name: "translate", value: TranslationType.Map } });
                }
                if (![AttributeTable.CustomValues, AttributeTable.Projects, AttributeTable.Suppliers].includes(value as AttributeTable)) {
                    onChange({ target: { name: "isCreatable", value: false } });
                }
            }
            return;
        }

        if (value === "text") {
            // Toggled towards text
            onChange({ target: { name, value } });
            onChange({ target: { name: "tableName", value: null } });
            onChange({ target: { name: "isMulti", value: null } });
            onChange({ target: { name: "isCreatable", value: null } });
            onChange({ target: { name: "translate", value: null } });
        } else if (value === "tree") {
            onChange({ target: { name, value } });
            onChange({ target: { name: "tableName", value: AttributeTable.TreeNodes } });
            onChange({ target: { name: "isMulti", value: false } });
            onChange({ target: { name: "isCreatable", value: false } });
            onChange({ target: { name: "translate", value: TranslationType.MapToLangProperty } });
        } else {
            // Toggled towards combobox
            onChange({ target: { name, value } });
            onChange({ target: { name: "tableName", value: null } });
            onChange({ target: { name: "isMulti", value: false } });
            onChange({ target: { name: "isCreatable", value: false } });
            onChange({ target: { name: "translate", value: TranslationType.Map } });
        }
    };

    const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;

        let value: boolean | TranslationType = checked;
        if (name === "translate") {
            value = checked ? TranslationType.MapAndTranslate : TranslationType.Map;
        } else if (name === "isCreatable" && value === checked) {
            onChange({ target: { name: "translate", value: TranslationType.Map } });
        }

        onChange({ target: { name, value } });
    };

    const submitForm = () => {
        if (!isCustomFieldValid) {
            return;
        }
        onApplyChanges();
    };

    const isValidFieldName = validateFieldName(row.title as string);

    const isValidInternalName = validateInternalName((row.title ?? "") as string, clientId);

    const getInternalNameHelperText = () => {
        if (isNew) {
            if (isValidInternalName) {
                return t(translationKeys.VDLANG_SUPERADMIN_INTERNAL_NAME_HINT);
            }
            return t(translationKeys.VDLANG_SUPERADMIN_INTERNAL_NAME_ALREADY_IN_USE);
        }
    };

    const getIsMultiTooltipText = (customField: Record<string, unknown>) => {
        if (!customField.isFilled) {
            return;
        }
        if (customField.isMulti) {
            return "This attribute can no longer be changed because the field is already in use as multi-value.";
        }
        if (customField.isUsedInEffectCategory) {
            return "This attribute can no longer be changed because the field is used in an effect category.";
        }
    };

    const isFilled = customField.isFilled as boolean;
    const isMulti = customField.isMulti as boolean;
    const isUsedInEffectCategory = customField.isUsedInEffectCategory as boolean;

    return (
        <ActionItemDialog
            open={open}
            onClose={onCancelChanges}
            action={isNew ? "create" : "edit"}
            item="customfield"
            primary={isNew ? "create_customfield" : "save_customfield"}
            onPrimary={onApplyChanges}
            primaryDisabled={!isCustomFieldValid || !isValidFieldName || !isValidInternalName}
            translate={translate}
        >
            <Form onSubmit={submitForm}>
                <FormGroup>
                    <TextField
                        required
                        label="Name (internal)"
                        autoFocus
                        name="title"
                        value={row.title ?? ""}
                        onChange={onChange}
                        disabled={!isNew}
                        error={!isValidFieldName || !isValidInternalName}
                        margin="normal"
                        helperText={getInternalNameHelperText()}
                    />
                    <TextField
                        required
                        label="Name (EN)"
                        name="translationEn"
                        value={row.translationEn ?? ""}
                        onChange={onChange}
                        error={!validateValue(row.translationEn as string)}
                        margin="normal"
                    />
                    <TextField
                        required
                        label="Name (DE)"
                        name="translationDe"
                        value={row.translationDe ?? ""}
                        onChange={onChange}
                        error={!validateValue(row.translationDe as string)}
                        margin="normal"
                    />
                    <TextField label="Hint (EN)" name="hintEn" value={row.hintEn ?? ""} onChange={onChange} margin="normal" />
                    <TextField label="Hint (DE)" name="hintDe" value={row.hintDe ?? ""} onChange={onChange} margin="normal" />
                    <Select
                        label="Type"
                        value={typeOptions.find((o) => o.value === row.type)}
                        options={typeOptions}
                        onChange={(option) => onSelectInputChanged("type", option)}
                        menuPortalTarget={document.body}
                        isSearchable
                        required
                        isDisabled={isFilled}
                    />
                    {row.type === AttributeType.Combobox || row.type === AttributeType.Tree ? (
                        <Tooltip title={getIsMultiTooltipText(customField)}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isMulti"
                                        checked={row.isMulti as boolean}
                                        value={row.isMulti}
                                        onChange={toggleCheckbox}
                                        disabled={isFilled && (isMulti || isUsedInEffectCategory)}
                                    />
                                }
                                label="isMulti"
                            />
                        </Tooltip>
                    ) : null}
                    {row.type === AttributeType.Combobox ? (
                        <>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isCreatable"
                                        checked={row.isCreatable as boolean}
                                        value={row.isCreatable}
                                        onChange={toggleCheckbox}
                                        disabled={
                                            (row.isFilled as boolean) ||
                                            (row.tableName !== AttributeTable.CustomValues &&
                                                row.tableName !== AttributeTable.Suppliers &&
                                                row.tableName !== AttributeTable.Projects &&
                                                row.tableName !== null)
                                        }
                                    />
                                }
                                label="isCreatable"
                            />
                            <Select
                                label="Tablename"
                                value={tableNameOptions.find((t) => t.value === row.tableName)}
                                options={tableNameOptions}
                                onChange={(option) => onSelectInputChanged("tableName", option)}
                                menuPortalTarget={document.body}
                                required
                                isDisabled={isFilled}
                            />
                        </>
                    ) : null}
                    {row.tableName === AttributeTable.CustomValues && !row.isCreatable ? (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="translate"
                                    checked={row.translate === TranslationType.MapAndTranslate}
                                    value={row.translate}
                                    onChange={toggleCheckbox}
                                    disabled={isFilled}
                                />
                            }
                            label="Translate values"
                        />
                    ) : null}
                </FormGroup>
            </Form>
        </ActionItemDialog>
    );
};

export default CustomFieldDialog;
