import { Checkbox, Divider, FormControlLabel, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { AttributeTarget, AttributeTitle } from "api-shared";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { useSuperAdminAttributeRelations, useSuperAdminAttributes } from "../../../domain/superadmin/attributes";
import { useSuperadminCustomers } from "../../../domain/superadmin/clients";
import { useSuperAdminMeasureConfigMutation, useSuperAdminMeasureConfigs } from "../../../domain/superadmin/measure-config";
import { translationKeys } from "../../../translations/main-translations";
import SuperAdminContainer from "../SuperAdminContainer";
import FieldRelationCard from "./FieldRelationCard";

const FieldAssignment = () => {
    const [activeClient, setActiveClient] = useState<number>();
    const [activeMeasureConfigId, setActiveMeasureConfigId] = useState<number>();

    const { t } = useTranslation();

    const clients = useSuperadminCustomers();
    const measureConfigs = useSuperAdminMeasureConfigs(activeClient, activeClient !== undefined);
    const attributes = useSuperAdminAttributes(activeClient, activeClient !== undefined);
    const attributeRelations = useSuperAdminAttributeRelations();
    const updateMeasureConfig = useSuperAdminMeasureConfigMutation();

    const handleChangeClient = (option: Option<number> | null) => {
        const clientId = option?.value;
        const clientsMeasureConfigs = measureConfigs?.data?.filter((mc) => mc.clientId === clientId);
        const measureConfigDefault = clientsMeasureConfigs && clientsMeasureConfigs.length > 0 ? clientsMeasureConfigs[0].id : undefined;
        setActiveMeasureConfigId(measureConfigDefault);
        setActiveClient(clientId);
    };

    const handleChangeMeasureConfig = (option: Option<number> | null) => {
        setActiveMeasureConfigId(option?.value);
    };

    if (!clients.isSuccess) {
        return <LoadingAnimation />;
    }

    const clientOptions = clients.data.map(({ id, name }) => ({ value: id, label: name }));

    const measureConfigOptions = measureConfigs.data
        ?.filter((mc) => mc.clientId === activeClient)
        .map(({ id, name }) => ({
            value: id,
            label: name,
        }));

    const sideBarRelations = attributeRelations.data?.filter(
        (rel) => rel.measureConfigId === activeMeasureConfigId && rel.target === AttributeTarget.Measure,
    );

    const effectCategoryRelations = attributeRelations.data?.filter(
        (rel) => rel.measureConfigId === activeMeasureConfigId && rel.target === AttributeTarget.EffectCategory,
    );

    const activeMeasureConfig = measureConfigs.data?.find(({ id }) => id === activeMeasureConfigId);

    const attributesInUse =
        attributeRelations.data
            ?.filter(({ measureConfigId }) => measureConfigId === activeMeasureConfigId)
            .map(({ attributeId }) => attributeId) ?? [];

    const hiddenAttributes =
        attributes.data
            ?.filter(({ title }) => title === AttributeTitle.DiscardReason || title === AttributeTitle.DiscardStatement)
            .map(({ id }) => id) ?? [];

    const attributeIdsInUse = [...attributesInUse, ...hiddenAttributes];

    const isFetching = attributeRelations.isFetching || attributeRelations.isRefetching;

    return (
        <>
            <SuperAdminContainer title="Process Configuration">
                <Divider />

                <Grid container spacing={1} p={2}>
                    <Grid item xs={6}>
                        <Select
                            margin="none"
                            label="Client"
                            value={clientOptions.find((t) => t.value === activeClient)}
                            options={clientOptions}
                            onChange={handleChangeClient}
                            menuPortalTarget={document.body}
                            isClearable
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            margin="none"
                            label="Measure Config"
                            value={measureConfigOptions?.find((t) => t.value === activeMeasureConfigId)}
                            options={measureConfigOptions}
                            onChange={handleChangeMeasureConfig}
                            menuPortalTarget={document.body}
                            isClearable
                        />
                    </Grid>
                </Grid>
                {activeClient != null && activeMeasureConfigId != null && activeMeasureConfig != null ? (
                    <Grid item xs={12}>
                        <Tooltip title={t(translationKeys.VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_HINT)}>
                            <FormControlLabel
                                value={t(translationKeys.VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_LABEL)}
                                control={
                                    <Checkbox
                                        checked={activeMeasureConfig.prefillGateAssignee}
                                        onChange={(e) => {
                                            updateMeasureConfig.mutate({
                                                clientId: activeClient,
                                                prefillGateAssignee: e.target.checked,
                                                id: activeMeasureConfigId,
                                            });
                                        }}
                                    />
                                }
                                label={t(translationKeys.VDLANG_SUPER_ADMIN_PREFILL_GATE_TASKS_LABEL)}
                                labelPlacement="start"
                            />
                        </Tooltip>
                    </Grid>
                ) : null}
            </SuperAdminContainer>

            <Grid container columnSpacing={1} mt={1}>
                {activeMeasureConfigId == null ? (
                    <Grid item xs={12}>
                        <Paper>
                            <Typography variant="subtitle2" color="primary" p={2}>
                                Select Client and Measure Config to see existing Attribute Relations
                            </Typography>
                        </Paper>
                    </Grid>
                ) : null}
                {attributes.isSuccess && activeClient != null && activeMeasureConfig && sideBarRelations && effectCategoryRelations ? (
                    <>
                        <Grid item xs={6}>
                            <FieldRelationCard
                                clientId={activeClient}
                                target={AttributeTarget.Measure}
                                attributes={attributes.data}
                                attributeIdsInUse={attributeIdsInUse}
                                relations={sideBarRelations}
                                title="Sidebar"
                                measureConfig={activeMeasureConfig}
                                // use key property to reset intenal state after changing measureConfig
                                key={activeMeasureConfig.id}
                                isFetching={isFetching}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FieldRelationCard
                                clientId={activeClient}
                                target={AttributeTarget.EffectCategory}
                                attributes={attributes.data}
                                attributeIdsInUse={attributeIdsInUse}
                                relations={effectCategoryRelations}
                                title="Effect category"
                                measureConfig={activeMeasureConfig}
                                // use key property to reset intenal state after changing measureConfig
                                key={activeMeasureConfig.id}
                                isFetching={isFetching}
                            />
                        </Grid>
                    </>
                ) : null}
            </Grid>
        </>
    );
};

export default FieldAssignment;
